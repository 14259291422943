import { UploadRes } from '../response/upload.response';
import httpClient from '../util/http-client';

export const UploadService = {
  uploadFile: async (
    file: File,
    bucketName: string,
  ): Promise<(UploadRes | any)[]> => {
    let data;
    let err;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await httpClient.post(`/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          bucketName: bucketName,
        },
      });
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
