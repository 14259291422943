import { useNavigate } from 'react-router';

export const useSSNavigate = () => {
  const navigate = useNavigate();

  const openAuth = () => {
    navigate('/auth', { replace: true });
  };

  const openHome = () => {
    navigate(`/`, { replace: true });
  };

  const openEmployee = () => {
    navigate(`/employee`, { replace: false });
  };

  const openAddEmployee = () => {
    navigate(`/employee/add`, { replace: false });
  };

  const openEmployeeDetail = (id: string) => {
    navigate(`/employee/${id}`, { replace: false });
  };

  const openUpdateEmployee = (id: string) => {
    navigate(`/employee/${id}/update`, { replace: false });
  };

  const openOfficeLocation = () => {
    navigate(`/office-location`, { replace: false });
  };

  const openAddOfficeLocation = () => {
    navigate(`/office-location/add`, { replace: false });
  };

  const openUpdateOfficeLocation = (id: string) => {
    navigate(`/office-location/${id}/update`, { replace: false });
  };

  const openEmployeeRole = () => {
    navigate(`/employee-role`, { replace: false });
  };

  const openAddEmployeeRole = () => {
    navigate(`/employee-role/add`, { replace: false });
  };

  const openUpdateEmployeeRole = (id: string) => {
    navigate(`/employee-role/${id}/update`, { replace: false });
  };

  const openPath = (path: string) => {
    navigate(path, { replace: false });
  };

  return {
    openAuth,
    openHome,
    openEmployee,
    openEmployeeDetail,
    openAddEmployee,
    openUpdateEmployee,
    openOfficeLocation,
    openAddOfficeLocation,
    openUpdateOfficeLocation,
    openEmployeeRole,
    openAddEmployeeRole,
    openUpdateEmployeeRole,
    openPath,
  };
};
