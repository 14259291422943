import { useEffect, useState } from 'react';
import { Button, Spinner, Form } from 'react-bootstrap';
import validator from 'validator';
import { I18nKey } from '../../i18n/i18n.key';
import { useTranslation } from 'react-i18next';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { ItemProp } from '../../model/item-prop.model';
import MultiSelectView from '../../view/multi-select.view';
import { EmployeeRequest } from '../../request/employee.request';
import { EmployeeService } from '../../service/employee.service';
import { OfficeLocationRes } from '../../response/office-location.response';
import { OfficeLocationSearchReq } from '../../request/office-location.request';
import { OfficeLocationService } from '../../service/office-location.service';
import { EmployeeRoleService } from '../../service/employee-role.service';
import { EmployeeRoleRes } from '../../response/employee-role.response';
import { Media } from '../../model/media.model';
import { v4 as uuidv4 } from 'uuid';
import { UploadService } from '../../service/upload.service';
import { Image } from 'react-bootstrap';

const AddEmployeePage = () => {
  const { t } = useTranslation();
  const { openEmployee } = useSSNavigate();

  const [profilePic, setProfilePic] = useState<File | null>(null);
  const [profilePicPreview, setProfilePicPreview] = useState<string>('');

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    employeeInfo: {
      name: '',
      phone: '',
      email: '',
    },
    status: '',
    password: '',
    officeLocationIdList: '',
    roleIdList: '',
    metaData: '',
    profilePic: '',
  });

  const emptyList: string[] = [];

  const [employeeReq, setEmployeeReq] = useState({
    employeeInfo: {
      name: '',
      phone: '',
      email: '',
    },
    status: 'active',
    password: '',
    officeLocationIdList: emptyList,
    roleIdList: emptyList,
  } as EmployeeRequest);

  const [officeLocationList, setOfficeLocationList] = useState<
    OfficeLocationRes[]
  >([]);
  const [employeeRoleList, setEmployeeRoleList] = useState<EmployeeRoleRes[]>(
    [],
  );

  const fetchData = async () => {
    const [officeLocationList, err] =
      await OfficeLocationService.getOfficeLocationList({
        pageNumber: 1,
        itemsPerPage: 600,
      } as OfficeLocationSearchReq);

    const [employeeRoleList, err2] =
      await EmployeeRoleService.getEmployeeRoleList({
        pageNumber: 1,
        itemsPerPage: 200,
      } as OfficeLocationSearchReq);

    setOfficeLocationList(officeLocationList);
    setEmployeeRoleList(employeeRoleList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const saveEmployee = async (employeeReq: EmployeeRequest) => {
    let verifiedPhoto: Media | undefined;
    try {
      if (profilePic) {
        const [uploadData, error] = await UploadService.uploadFile(
          profilePic,
          'oscillate-employee-profile',
        );

        if (error) {
          console.error('File upload failed:', error);
        } else {
          console.log('File uploaded successfully:', uploadData);
          verifiedPhoto = {
            id: uuidv4(),
            url: { main: uploadData.Location },
            aspectRatio: 1,
            type: 'IMAGE',
            tagList: ['Verified Employee Photo'],
          } as Media;
        }
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      return;
    }

    const [data, error] = await EmployeeService.saveEmployee(employeeReq);

    if (error) {
      setLoading(false);
    } else {
      openEmployee();
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let isError = false;

    const _errors: any = {
      employeeInfo: {},
      metaData: {},
    };

    // validation
    if (
      !validator.isLength(employeeReq.employeeInfo.name, { min: 2, max: 30 })
    ) {
      isError = true;
      _errors.employeeInfo.name = 'Please enter valid Name';
    }
    if (!validator.isMobilePhone(employeeReq.employeeInfo.phone, 'en-IN')) {
      isError = true;
      _errors.employeeInfo.phone = 'Please enter valid phone';
    }
    if (
      employeeReq.employeeInfo.email.length > 0 &&
      !validator.isEmail(employeeReq.employeeInfo.email)
    ) {
      isError = true;
      _errors.employeeInfo.email = 'Please enter valid email';
    }

    if (employeeReq.officeLocationIdList.length <= 0) {
      isError = true;
      _errors.officeLocationIdList = 'Please select office';
    }

    if (employeeReq.roleIdList.length == 0) {
      isError = true;
      _errors.roleIdList = 'Please select role';
    }

    if (isError) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      saveEmployee(employeeReq);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setProfilePic(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const formStyle = {
    width: '96%',
    maxWidth: '769px',
    margin: 'auto',
  };

  return (
    <section
      className="my-3"
      style={{
        overflow: 'auto',
        height: '100vh',
      }}
    >
      <div
        className="rounded-16 bg-white p-4 ft-14 mt-lg-0 mt-5 custom-shadow text-nero z-1"
        style={formStyle}
      >
        <h5 className="mb-4 text-nero">{'Add Employee'}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>{t(I18nKey.CANDIDATE_NAME_TEXT)}</Form.Label>

              <Form.Control
                required
                type="text"
                value={employeeReq.employeeInfo.name}
                isInvalid={!!errors.employeeInfo.name}
                onChange={(e) =>
                  setEmployeeReq((preState) => {
                    return {
                      ...preState,
                      employeeInfo: {
                        ...preState.employeeInfo,
                        name: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.employeeInfo.name ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_CANDIDATE_NAME_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.employeeInfo.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="phone" className="mb-3">
              <Form.Label>{t(I18nKey.CANDIDATE_PHONE_TEXT)}</Form.Label>

              <Form.Control
                required
                type="text"
                maxLength={10}
                value={employeeReq.employeeInfo.phone}
                isInvalid={!!errors.employeeInfo.phone}
                onChange={(e) =>
                  setEmployeeReq((preState) => {
                    return {
                      ...preState,
                      employeeInfo: {
                        ...preState.employeeInfo,
                        phone: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.employeeInfo.phone ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_CANDIDATE_PHONE_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.employeeInfo.phone}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>{t(I18nKey.CANDIDATE_EMAIL_TEXT)}</Form.Label>

              <Form.Control
                required
                type="text"
                value={employeeReq.employeeInfo.email}
                isInvalid={!!errors.employeeInfo.email}
                onChange={(e) =>
                  setEmployeeReq((preState) => {
                    return {
                      ...preState,
                      employeeInfo: {
                        ...preState.employeeInfo,
                        email: e.target.value,
                      },
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.employeeInfo.email ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.ENTER_CANDIDATE_EMAIL_TEXT)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.employeeInfo.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="status" className="mb-3">
              <Form.Label>{t(I18nKey.STATUS_TEXT)}</Form.Label>

              <Form.Select
                required
                value={employeeReq.status}
                isInvalid={!!errors.status}
                onChange={(e) =>
                  setEmployeeReq((preState) => {
                    return {
                      ...preState,
                      status: e.target.value,
                    };
                  })
                }
                className={`ft-14 px-1 shadow-none border ${
                  errors.status ? 'border-danger' : ''
                } `}
                placeholder={t(I18nKey.SELECT_STATUS)}
                defaultValue={employeeReq.status}
              >
                {[
                  {
                    value: 'blocked',
                    label: 'Blocked',
                  } as ItemProp,
                  {
                    value: 'active',
                    label: 'Active',
                  } as ItemProp,
                  {
                    value: 'inActive',
                    label: 'InActive',
                  } as ItemProp,
                ].map((itemProp: ItemProp) => {
                  return (
                    <option value={itemProp.value}>{itemProp.label}</option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="officeLocationIdList" className="mb-3">
              <MultiSelectView
                id="officeLocationIdList"
                dataList={
                  officeLocationList?.map(
                    (officeLocationRes: OfficeLocationRes) => {
                      return {
                        label: officeLocationRes.name.en,
                        value: officeLocationRes.id,
                      } as ItemProp;
                    },
                  ) ?? []
                }
                onChange={(data: any[]) =>
                  setEmployeeReq((preState) => {
                    return {
                      ...preState,
                      officeLocationIdList: data,
                    };
                  })
                }
                placeholder={'Select Office Location'}
                title={'Office Location'}
                isInvalid={!!errors.officeLocationIdList}
                validationMessage={errors.officeLocationIdList}
              />
            </Form.Group>

            <Form.Group controlId="roleIdList" className="mb-3">
              <MultiSelectView
                id="roleIdList"
                dataList={
                  employeeRoleList?.map((employeeRoleRes: EmployeeRoleRes) => {
                    return {
                      label: employeeRoleRes.name.en,
                      value: employeeRoleRes.id,
                    } as ItemProp;
                  }) ?? []
                }
                onChange={(data: any[]) =>
                  setEmployeeReq((preState) => {
                    return {
                      ...preState,
                      roleIdList: data,
                    };
                  })
                }
                placeholder={'Select Role'}
                title={'Role'}
                isInvalid={!!errors.roleIdList}
                validationMessage={errors.roleIdList}
              />
            </Form.Group>

            <Form.Group controlId="profilePic" className="mb-3">
              <Form.Label>{'Profile Picture'}</Form.Label>
              <Form.Control
                type="file"
                accept="image/*" // Ensures only image files can be selected
                onChange={handleFileChange}
                className={`ft-14 px-1 shadow-none border ${
                  errors.profilePic ? 'border-danger' : ''
                } `}
              />
              {profilePicPreview && (
                <div className="mt-3">
                  <Image
                    src={profilePicPreview}
                    roundedCircle
                    width={100}
                    height={100}
                  />
                </div>
              )}
            </Form.Group>
            <div className="text-employee mt-4">
              <Button
                variant="primary"
                type="submit"
                className="text-white ft-14 font-weight-600"
              >
                {t(I18nKey.SUBMIT)}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </section>
  );
};

export default AddEmployeePage;
