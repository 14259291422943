import {
  Navbar,
  Form,
  FormControl,
  Button,
  Nav,
  NavDropdown,
} from 'react-bootstrap';

export interface HeaderComponentProp {
  title: string;
  childElement: any;
}

export const HeaderComponent: React.FC<HeaderComponentProp> = ({
  title,
  childElement,
}) => {
  return (
    <div
      className="px-4 py-2 d-flex align-items-center justify-content-between"
      style={{
        backgroundColor: '#003f91',
        color: '#FFFFFF',
        height: '48px',
      }}
    >
      <h4>{title}</h4>
      {childElement}
    </div>
  );
};
