import { Form, Button } from 'react-bootstrap';
import { useState } from 'react';

export interface OfficeLocationFilterComponentProp {
  onSubmit: any;
}
export const OfficeLocationFilterComponent: React.FC<
  OfficeLocationFilterComponentProp
> = ({ onSubmit }) => {
  const [searchText, setSearchText] = useState('');

  return (
    <Form
      style={{
        display: 'flex',
        height: '42px',
        gap: '24px',
        marginTop: 2,
      }}
    >
      <Form.Group controlId="formHorizontalText">
        <Form.Control
          type="text"
          placeholder="Enter text"
          onChange={(e: any) => setSearchText(e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Button
          onClick={() => {
            onSubmit(searchText);
          }}
        >
          Submit
        </Button>
      </Form.Group>
    </Form>
  );
};
