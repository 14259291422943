import { useState } from 'react';
import { Button, Spinner, Form } from 'react-bootstrap';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../i18n/i18n.key';
import { useTranslation } from 'react-i18next';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { EmployeeRoleService } from '../../service/employee-role.service';
import { EmployeeRoleRequest } from '../../request/employee-role.request';

const AddEmployeeRolePage = () => {
  const { t } = useTranslation();
  const { openEmployeeRole } = useSSNavigate();

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
  });
  const [name, setName] = useState('');

  const saveEmployeeRole = async (employeeRoleReq: EmployeeRoleRequest) => {
    const [data, error] =
      await EmployeeRoleService.saveEmployeeRole(employeeRoleReq);

    if (error) {
      setLoading(false);
    } else {
      openEmployeeRole();
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (!validator.isLength(name, { min: 2, max: 30 })) {
      _errors.name = 'Please enter valid Name';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      const employeeRoleReq = {
        name: {
          en: name,
        },
      } as EmployeeRoleRequest;
      saveEmployeeRole(employeeRoleReq);
    }
  };

  const formStyle = {
    width: '96%',
    maxWidth: '769px',
    margin: 'auto',
  };

  return (
    <section className="my-3">
      <div
        className="rounded-16 bg-white p-4 ft-14 mt-lg-0 mt-5 custom-shadow text-nero z-1"
        style={formStyle}
      >
        <h5 className="mb-4 text-nero">Add Role</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Role Name</Form.Label>

              <Form.Control
                required
                type="text"
                value={name}
                isInvalid={!!errors.name}
                onChange={(e) => setName(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.name ? 'border-danger' : ''
                } `}
                placeholder={'Enter role name'}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="text-employeeRole mt-4">
              <Button
                variant="primary"
                type="submit"
                className="text-white ft-14 font-weight-600"
              >
                {t(I18nKey.SUBMIT)}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </section>
  );
};

export default AddEmployeeRolePage;
