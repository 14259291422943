import { Outlet } from 'react-router';
import { useWindow } from '../util/window-helper';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import FooterComponent from './shared/footer.component';
import { UserService } from '../service/user.service';
import { SecurityManager } from '../security/security-manager';
import SideBarComponent from './shared/sidebar/sidebar.component';
import { HeaderComponent } from './shared/header/header.component';

const MainHolder = () => {
  const { isMobile } = useWindow();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    UserService.getUserById(SecurityManager.getUid());
  }, []);

  return (
    <>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <div
          style={{
            position: 'relative',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'start',
            alignContent: 'start',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <SideBarComponent />
          <div
            style={{
              height: '100vh',
              width: '100%',
            }}
          >
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default MainHolder;
