import { useEffect, useRef, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { ItemProp } from '../model/item-prop.model';
import { useDebounce } from '../util/use-debounce.hook';

export interface MultiSelectViewProps {
  id: string;
  title: string;
  placeholder: string;
  dataList: ItemProp[];
  onChange: (values: (string | number)[]) => void;
  defaultSelected?: string[];
  isInvalid: boolean | false;
  validationMessage: string | '';
}

const MultiSelectView: React.FC<MultiSelectViewProps> = ({
  id,
  title,
  placeholder,
  dataList,
  onChange,
  defaultSelected,
  isInvalid,
  validationMessage,
}) => {
  const [searchText, setSearchText] = useState('');

  const filterText = useDebounce(searchText, 500);

  const [open, setOpen] = useState(false);
  const [selectedItemList, setSelectedItemList] = useState<(string | number)[]>(
    defaultSelected ?? [],
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (value: any) => {
    setSelectedItemList((selectedItemList) => {
      const _selectedItemList = [...selectedItemList];
      const index = _selectedItemList.indexOf(value);
      if (index > -1) {
        _selectedItemList.splice(index, 1);
      } else {
        _selectedItemList.push(value);
      }

      onChange(_selectedItemList);

      return [..._selectedItemList];
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const updateSearchFilter = (text: string) => {
    setSearchText(text.toLowerCase());
  };

  useEffect(() => {
    if (defaultSelected && selectedItemList.length == 0) {
      setSelectedItemList(defaultSelected);
    }
  }, [defaultSelected]);

  return (
    <Row
      key={`key_${id}`}
      className={`d-flex align-items-center mb-4`}
      id={`multi_select_${id}`}
      style={{ maxWidth: 374, position: 'relative' }}
      ref={dropdownRef}
    >
      <Button
        id={id}
        className={`text-start d-flex align-items-center justify-content-between text-white`}
        onClick={() => {
          setOpen((previous) => !previous);
        }}
      >
        <span>
          {selectedItemList.length > 0
            ? `${selectedItemList.length + ' ' + title} Selected`
            : placeholder}
        </span>
        {!open && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-down"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        )}

        {open && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-up"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
            />
          </svg>
        )}
      </Button>

      {open && (
        <div
          style={{ top: 42, overflow: 'auto', maxHeight: 232 }}
          className="rounded-8 bg-white p-2 ft-14 mt-2 mt-lg-0 mt-5 custom-shadow text-nero z-1 position-absolute"
        >
          <Form.Control
            type="text"
            placeholder="Search"
            className="my-2"
            onChange={(e: any) => updateSearchFilter(e.target.value)}
          />
          {dataList
            .filter((data: ItemProp) => {
              if (
                filterText.length == 0 ||
                selectedItemList.indexOf(data.value) !== -1
              ) {
                return true;
              } else {
                return data.label.toLocaleLowerCase().includes(filterText);
              }
            })
            // .sort((a: ItemProp, b: ItemProp) => {
            //   const aSelected = selectedItemList.indexOf(a.value) !== -1;
            //   const bSelected = selectedItemList.indexOf(b.value) !== -1;
            //   if (aSelected && !bSelected) return -1;
            //   if (!aSelected && bSelected) return 1;
            //   return 0;
            // })
            .map((data: ItemProp, index: number) => {
              return (
                <Form.Check
                  key={`multi_select_row_${index}_${id}`}
                  defaultChecked={selectedItemList.indexOf(data.value) != -1}
                  checked={selectedItemList.indexOf(data.value) != -1}
                  label={data.label}
                  onClick={() => {
                    handleSelect(data.value);
                  }}
                  name="group1"
                  type="checkbox"
                  id={`multi_select_${index}_${id}`}
                />
              );
            })}
        </div>
      )}
      <>
        {isInvalid && (
          <div className="custom-invalid-feedback">{validationMessage}</div>
        )}
      </>
    </Row>
  );
};

export default MultiSelectView;
