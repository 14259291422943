import { useEffect, useRef, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { EmployeeService } from '../../service/employee.service';
import { EmployeeRes } from '../../response/employee.response';
import { AttendanceRes } from '../../response/attendance.response';
import { AttendanceService } from '../../service/attendance.service';
import { AttendanceSearchReq } from '../../request/attendance.request';
import dayjs from 'dayjs';
import { Image } from 'react-bootstrap';

const EmployeeDetailPage = () => {
  const itemsPerPage = 1000;

  const pageNumber = useRef(1);

  const { t } = useTranslation();
  const { openEmployee } = useSSNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(true); // Set initial loading state to true
  const [employee, setEmployee] = useState<EmployeeRes | null>(null);
  const [attendanceList, setAttendanceList] = useState<AttendanceRes[]>([]);

  const [attendanceSearchReq, setAttendanceSearchReq] =
    useState<AttendanceSearchReq>({
      employeeIdList: [id],
      startDate: (() => {
        const date = new Date();
        date.setDate(2);
        date.setHours(0, 0, 0);
        return date;
      })(),
      endDate: (() => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        date.setHours(23, 59, 59);
        return date;
      })(),
      pageNumber: pageNumber.current,
      itemsPerPage: itemsPerPage,
    } as AttendanceSearchReq);

  const getEmployee = async () => {
    const [employee, error] = await EmployeeService.getEmployeeById(
      id as string,
    );

    if (error) {
      openEmployee();
    } else {
      setEmployee(employee);
    }

    setLoading(false); // Set loading to false after data is fetched
  };

  const getAttendance = async () => {
    const [_attendanceList, error] =
      await AttendanceService.getAttendanceList(attendanceSearchReq);

    if (!error) {
      setAttendanceList(_attendanceList);
    }
  };

  useEffect(() => {
    getEmployee();
    getAttendance();
  }, []);

  const formatDate = (date: Date) => {
    return dayjs(date).year() === 1
      ? '-'
      : dayjs(date).format('dddd, DD MMM YYYY');
  };

  const formatTime = (date: Date) => {
    return dayjs(date).year() === 1 ? '-' : dayjs(date).format('HH:mm A');
  };

  const formatWorkingTime = (seconds: number) => {
    if (seconds <= 0) return '-';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const downloadReport = async () => {
    setLoading(true);
    await AttendanceService.downLoadReportList(attendanceSearchReq);
    setLoading(false);
  };

  const handleFilter = () => {
    pageNumber.current = 1; // Reset to first page for new filter
    getAttendance();
  };

  return (
    <section
      className="my-3"
      style={{
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <div
        className="rounded-16 bg-white p-4 ft-14 mt-lg-0 mt-5 custom-shadow text-nero z-1"
        style={{
          width: '96%',
          maxWidth: '769px',
          margin: 'auto',
        }}
      >
        <h5 className="mb-4 text-nero">{'Employee Detail'}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <div>
            {employee && (
              <div>
                {employee.verifiedPhoto?.url?.main && (
                  <div className="my-2">
                    <Image
                      src={employee.verifiedPhoto?.url?.main}
                      roundedCircle
                      width={100}
                      height={100}
                    />
                  </div>
                )}
                <p>
                  <strong>Name:</strong> {employee.employeeInfo.name}
                </p>
                <p>
                  <strong>Phone:</strong> {employee.employeeInfo.phone}
                </p>
                <p>
                  <strong>Email:</strong> {employee.employeeInfo.email}
                </p>
                <p>
                  <strong>Status:</strong> {employee.status}
                </p>
                <p>
                  <strong>Employee Number:</strong> {employee.employeeNumber}
                </p>
              </div>
            )}
            <h5 className="mt-4">{'Attendance Report'}</h5>
            <div className="d-flex mb-3">
              <Form.Control
                type="date"
                onChange={(e) =>
                  setAttendanceSearchReq((preState: any) => {
                    return {
                      ...preState,
                      startDate: (() => {
                        const date = new Date(e.target.value);
                        date.setHours(0, 0, 0);
                        return date;
                      })(),
                    };
                  })
                }
                className="mr-2"
              />
              <Form.Control
                type="date"
                onChange={(e) =>
                  setAttendanceSearchReq((preState: any) => {
                    return {
                      ...preState,
                      endDate: (() => {
                        const date = new Date(e.target.value);
                        date.setHours(23, 59, 59);
                        return date;
                      })(),
                    };
                  })
                }
                className="mr-2"
              />
              <Button onClick={handleFilter}>{'Filter'}</Button>
            </div>
            <table className="table mt-3">
              <thead>
                <tr>
                  <th>{'Date'}</th>
                  <th>{'Check-In Time'}</th>
                  <th>{'Check-Out Time'}</th>
                  <th>{'Working Time'}</th>
                </tr>
              </thead>
              <tbody>
                {attendanceList &&
                  attendanceList.map((attendance: AttendanceRes, index) => {
                    return (
                      <tr key={index}>
                        <td>{formatDate(attendance.createdOn)}</td>
                        <td>{formatTime(attendance?.logInInfo?.time)}</td>
                        <td>{formatTime(attendance?.logOutInfo?.time)}</td>
                        <td>{formatWorkingTime(attendance?.workingTime)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <Button className="mt-3" onClick={downloadReport}>
              {'Download Report'}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default EmployeeDetailPage;
