import {
  OfficeLocationRequest,
  OfficeLocationSearchReq,
} from '../request/office-location.request';
import { OfficeLocationRes } from '../response/office-location.response';
import httpClient from '../util/http-client';

export const OfficeLocationService = {
  getOfficeLocationById: async (
    id: string,
  ): Promise<(OfficeLocationRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/office-location/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getOfficeLocationList: async (
    OfficeLocationSearchReq: OfficeLocationSearchReq,
  ): Promise<(OfficeLocationRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(
        '/office-location-search',
        OfficeLocationSearchReq,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveOfficeLocation: async (
    officeLocationRequest: OfficeLocationRequest,
  ): Promise<(OfficeLocationRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(
        `/office-location`,
        officeLocationRequest,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateOfficeLocation: async (
    id: string,
    officeLocationRequest: OfficeLocationRequest,
  ): Promise<(OfficeLocationRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(
        `/office-location/${id}`,
        officeLocationRequest,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
