import { createBrowserRouter } from 'react-router-dom';
import { SecureRoute } from '../security/secure.route';
import MainHolder from '../page/main.holder';
import AuthPage from '../page/auth/auth.page';
import HomePage from '../page/home/home.page';
import EmployeePage from '../page/employee/employee.page';
import AddEmployeePage from '../page/employee/employee-add.page';
import UpdateEmployeePage from '../page/employee/employee-update.page';
import OfficeLocationPage from '../page/office-location/office-location.page';
import AddOfficeLocationPage from '../page/office-location/office-location-add.page';
import UpdateOfficeLocationPage from '../page/office-location/office-location-update.page';
import EmployeeRolePage from '../page/employee-role/employee-role.page';
import AddEmployeeRolePage from '../page/employee-role/employee-role-add.page';
import UpdateEmployeeRolePage from '../page/employee-role/employee-role-update.page';
import EmployeeDetailPage from '../page/employee/employee-detail.page';

export const mainRoute = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthPage />,
    children: [],
  },
  {
    element: <MainHolder />,
    children: [
      {
        path: '',
        element: <SecureRoute element={<HomePage />} />,
        children: [],
      },
      {
        path: '/employee',
        element: <SecureRoute element={<EmployeePage />} />,
        children: [],
      },
      {
        path: '/employee/add',
        element: <SecureRoute element={<AddEmployeePage />} />,
        children: [],
      },
      {
        path: '/employee/:id/update',
        element: <SecureRoute element={<UpdateEmployeePage />} />,
        children: [],
      },
      {
        path: '/employee/:id',
        element: <SecureRoute element={<EmployeeDetailPage />} />,
        children: [],
      },
      {
        path: '/office-location',
        element: <SecureRoute element={<OfficeLocationPage />} />,
        children: [],
      },
      {
        path: '/office-location/add',
        element: <SecureRoute element={<AddOfficeLocationPage />} />,
        children: [],
      },
      {
        path: '/office-location/:id/update',
        element: <SecureRoute element={<UpdateOfficeLocationPage />} />,
        children: [],
      },
      {
        path: '/employee-role',
        element: <SecureRoute element={<EmployeeRolePage />} />,
        children: [],
      },
      {
        path: '/employee-role/add',
        element: <SecureRoute element={<AddEmployeeRolePage />} />,
        children: [],
      },
      {
        path: '/employee-role/:id/update',
        element: <SecureRoute element={<UpdateEmployeeRolePage />} />,
        children: [],
      },
    ],
  },
  {
    path: '*',
    element: <h1>Page not found</h1>,
  },
]);
