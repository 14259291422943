import { useEffect, useState } from 'react';
import { Button, Spinner, Form } from 'react-bootstrap';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../i18n/i18n.key';
import { useTranslation } from 'react-i18next';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useParams } from 'react-router';
import { ItemProp } from '../../model/item-prop.model';
import { OfficeLocationRequest } from '../../request/office-location.request';
import { OfficeLocationService } from '../../service/office-location.service';
import { Location } from '../../model/location.model';

const UpdateOfficeLocationPage = () => {
  const { t } = useTranslation();
  const { openOfficeLocation } = useSSNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    latitude: '',
    longitude: '',
    fpsId: '',
  });
  const [name, setName] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [fpsId, setfpsId] = useState('');

  const updateOfficeLocation = async (
    officeLocationReq: OfficeLocationRequest,
  ) => {
    const [data, error] = await OfficeLocationService.updateOfficeLocation(
      id as string,
      officeLocationReq,
    );

    if (error) {
      setLoading(false);
    } else {
      openOfficeLocation();
    }
  };

  const getOfficeLocation = async () => {
    const [officeLocation, error] =
      await OfficeLocationService.getOfficeLocationById(id as string);

    setLoading(false);
    if (error) {
      openOfficeLocation();
    } else {
      setName(officeLocation.name.en);
      setLatitude(officeLocation.location.coordinates[0]);
      setLongitude(officeLocation.location.coordinates[1]);
      setfpsId(officeLocation.metaData[`setfpsId`]);
    }
  };

  useEffect(() => {
    getOfficeLocation();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (!validator.isLength(name, { min: 2, max: 30 })) {
      _errors.name = 'Please enter valid Name';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      const officeLocationReq = {
        name: {
          en: name,
        },
        metaData: {
          fpsId: fpsId,
        },
        location: {
          type: 'Point',
          coordinates: [latitude, longitude],
        } as Location,
      } as OfficeLocationRequest;
      updateOfficeLocation(officeLocationReq);
    }
  };

  const formStyle = {
    width: '96%',
    maxWidth: '769px',
    margin: 'auto',
  };

  return (
    <section className="my-3">
      <div
        className="rounded-16 bg-white p-4 ft-14 ms-lg-5 mt-lg-0 mt-5 custom-shadow text-nero z-1"
        style={formStyle}
      >
        <h5 className="mb-4 text-nero">{'Update Office Location'}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>{'Office name'}</Form.Label>

              <Form.Control
                required
                type="text"
                value={name}
                isInvalid={!!errors.name}
                onChange={(e) => setName(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.name ? 'border-danger' : ''
                } `}
                placeholder={'Enter Office name'}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="latitude" className="mb-3">
              <Form.Label>{'Latitude'}</Form.Label>

              <Form.Control
                required
                type="number"
                value={latitude}
                isInvalid={!!errors.latitude}
                onChange={(e) => setLatitude(Number(e.target.value))}
                className={`ft-14 px-1 shadow-none border ${
                  errors.latitude ? 'border-danger' : ''
                } `}
                placeholder={'Enter latitude'}
                defaultValue={latitude}
              />
              <Form.Control.Feedback type="invalid">
                {errors.latitude}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="longitude" className="mb-3">
              <Form.Label>{'Longitude'}</Form.Label>

              <Form.Control
                required
                type="number"
                value={longitude}
                isInvalid={!!errors.longitude}
                onChange={(e) => setLongitude(Number(e.target.value))}
                className={`ft-14 px-1 shadow-none border ${
                  errors.longitude ? 'border-danger' : ''
                } `}
                placeholder={'Enter longitude'}
                defaultValue={longitude}
              />
              <Form.Control.Feedback type="invalid">
                {errors.longitude}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="fpsId" className="mb-3">
              <Form.Label>{'fpsId'}</Form.Label>

              <Form.Control
                required
                type="string"
                value={fpsId}
                isInvalid={!!errors.fpsId}
                onChange={(e) => setfpsId(e.target.value)}
                className={`ft-14 px-1 shadow-none border ${
                  errors.fpsId ? 'border-danger' : ''
                } `}
                placeholder={'Enter fpsId'}
                defaultValue={fpsId}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fpsId}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="text-officeLocation mt-4">
              <Button
                variant="primary"
                type="submit"
                className="text-white ft-14 font-weight-600"
              >
                {t(I18nKey.SUBMIT)}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </section>
  );
};

export default UpdateOfficeLocationPage;
