import {
  EmployeeRoleRequest,
  EmployeeRoleSearchReq,
} from '../request/employee-role.request';
import { EmployeeRoleRes } from '../response/employee-role.response';
import httpClient from '../util/http-client';

export const EmployeeRoleService = {
  getEmployeeRoleById: async (
    id: string,
  ): Promise<(EmployeeRoleRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/employee-role/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getEmployeeRoleList: async (
    EmployeeRoleSearchReq: EmployeeRoleSearchReq,
  ): Promise<(EmployeeRoleRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(
        '/employee-role-search',
        EmployeeRoleSearchReq,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveEmployeeRole: async (
    employeeRoleRequest: EmployeeRoleRequest,
  ): Promise<(EmployeeRoleRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/employee-role`, employeeRoleRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateEmployeeRole: async (
    id: string,
    employeeRoleRequest: EmployeeRoleRequest,
  ): Promise<(EmployeeRoleRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(
        `/employee-role/${id}`,
        employeeRoleRequest,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
