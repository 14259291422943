import { useState } from 'react';
import { MenuItem } from '../../../model/menu-item.model';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import MenuItemComponent from './menu-item.component';
import CollapsedMenuItemComponent from './collapsed-menu-item';
import { SecurityManager } from '../../../security/security-manager';

const SideBarComponent = () => {
  const { isMobile } = useWindow();

  const { theme } = useTheme();
  const [collapsed, setCollapsed] = useState(isMobile);

  const menuList = [
    {
      id: 'employee',
      name: 'Employee',
      icon: 'manage',
      path: '/employee',
      subMenuList: [],
    } as MenuItem,
    {
      id: 'office-location',
      name: 'Office Location',
      icon: 'manage',
      path: '/office-location',
      subMenuList: [],
    } as MenuItem,
    {
      id: 'role',
      name: 'Employee Role',
      icon: 'manage',
      path: '/employee-role',
      subMenuList: [],
    } as MenuItem,
  ];

  return (
    <>
      {collapsed ? (
        <div
          style={{
            backgroundColor: theme.primaryColor,
            width: 84,
            height: '100vh',
            padding: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              width: '100%',
            }}
          >
            <img src="/images/saissy_logo.svg" alt="" width={64} height={30} />
          </div>

          <div
            style={{
              marginTop: 16,
              overflow: 'auto',
            }}
          >
            {menuList.map((menuItem: MenuItem, index: number) => {
              return (
                <CollapsedMenuItemComponent
                  isFirst={index == 0}
                  isLast={index == menuList.length - 1}
                  menuItem={menuItem}
                />
              );
            })}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignContent: 'start',
              width: '100%',
              marginTop: 16,
              cursor: 'pointer',
            }}
            onClick={() => {
              SecurityManager.logout();
            }}
          >
            <img
              src="/images/log-out.svg"
              alt=""
              width={64}
              height={64}
              style={{ padding: 16 }}
            />
          </div>
          <img
            src="/images/open_menu.svg"
            alt=""
            width={64}
            height={64}
            style={{
              padding: 16,
              cursor: 'pointer',
            }}
            onClick={() => {
              setCollapsed(false);
            }}
          />
        </div>
      ) : (
        <div
          style={{
            backgroundColor: theme.primaryColor,
            width: 284,
            height: '100vh',
            padding: 24,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              width: '100%',
            }}
          >
            <img src="/images/saissy_logo.svg" alt="" width={64} height={30} />
            <img
              src="/images/close_menu.svg"
              alt=""
              width={32}
              height={32}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setCollapsed(true);
              }}
            />
          </div>

          <div
            style={{
              height: `calc(100% - 100px)`,
              marginTop: 16,
              overflow: 'auto',
            }}
          >
            {menuList.map((menuItem: MenuItem, index: number) => {
              return (
                <MenuItemComponent
                  key={`side_menu${index}`}
                  isFirst={index == 0}
                  isLast={index == menuList.length - 1}
                  menuItem={menuItem}
                />
              );
            })}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignContent: 'start',
              width: `calc('100%' - '48px')`,
              paddingTop: 16,
              paddingBottom: 16,
              position: 'absolute',
              bottom: 0,
              padding: 16,
              cursor: 'pointer',
            }}
            onClick={() => {
              SecurityManager.logout();
            }}
          >
            <img src="/images/log-out.svg" alt="" width={20} height={20} />
            <p style={{ marginLeft: 16, color: theme.white }}>Log out</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SideBarComponent;
