import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FloatingButtonView } from '../../view/floating-button.view';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../themes/theme.provider';
import { OfficeLocationSearchReq } from '../../request/office-location.request';
import { OfficeLocationRes } from '../../response/office-location.response';
import { OfficeLocationService } from '../../service/office-location.service';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HeaderComponent } from '../shared/header/header.component';
import { OfficeLocationFilterComponent } from './office-location-filter.component';

const OfficeLocationPage = () => {
  const itemsPerPage = 30;

  const { t } = useTranslation();
  const { theme } = useTheme();

  const searchText = useRef('');
  const pageNumber = useRef(1);
  const [hasMore, setHasMore] = useState(true);

  const [officeLocationList, setOfficeLocationList] = useState<
    OfficeLocationRes[]
  >([]);
  const { openAddOfficeLocation, openUpdateOfficeLocation } = useSSNavigate();

  const fetchOfficeLocation = async () => {
    const [_officeLocationList, err] =
      await OfficeLocationService.getOfficeLocationList({
        searchText: searchText.current,
        pageNumber: pageNumber.current,
        itemsPerPage: itemsPerPage,
      } as OfficeLocationSearchReq);

    if (!err) {
      if (pageNumber.current == 1) {
        setOfficeLocationList(_officeLocationList);
      } else {
        setOfficeLocationList((previous) => [
          ...previous,
          ..._officeLocationList,
        ]);
      }

      if (_officeLocationList && _officeLocationList.length > 0) {
        pageNumber.current = pageNumber.current + 1;
      }

      if (_officeLocationList && _officeLocationList.length == itemsPerPage) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  };

  useEffect(() => {
    fetchOfficeLocation();
  }, []);

  const refresh = () => {
    pageNumber.current = 1;
    fetchOfficeLocation();
  };

  const searchOfficeLocation = (_searchText: string) => {
    pageNumber.current = 1;
    searchText.current = _searchText;
    fetchOfficeLocation();
  };

  return (
    <>
      <HeaderComponent
        childElement={
          <OfficeLocationFilterComponent onSubmit={searchOfficeLocation} />
        }
        title={'Office Location Details'}
      />
      <section
        id="scrollableOfficeLocation"
        style={{
          height: `calc(100vh - 48px)`,
          overflow: 'auto',
          paddingTop: 8,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <InfiniteScroll
          dataLength={officeLocationList.length}
          next={fetchOfficeLocation}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableOfficeLocation"
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>
              &#8595; Pull down to refresh
            </h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          }
        >
          <Container>
            <Row
              className="rounded p-2"
              style={{
                backgroundColor: theme.greyColor,
              }}
            >
              <Col xs={4}>Name</Col>
              <Col xs={4}>Status</Col>
              <Col xs={4}>Actions</Col>
            </Row>
            {officeLocationList?.map((officeLocation: OfficeLocationRes) => {
              return (
                <Row className="rounded border p-2">
                  <Col xs={4}>{officeLocation.name.en}</Col>
                  <Col xs={4}>{officeLocation.location.type}</Col>
                  <Col xs={4}>
                    <Button
                      variant="link"
                      className="text-primary text-decoration-underline p-0 font-weight-600"
                      onClick={() => {
                        openUpdateOfficeLocation(officeLocation.id);
                      }}
                    >
                      <img
                        src="/images/edit_button.svg"
                        alt=""
                        width={20}
                        height={20}
                      />
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </Container>
        </InfiniteScroll>
        <FloatingButtonView
          innerElement={
            <>
              <img src="/images/add.svg" alt="" />
              <span className="ms-2">{'Add Office Location'}</span>
            </>
          }
          onClick={() => {
            openAddOfficeLocation();
          }}
        />
      </section>
    </>
  );
};

export default OfficeLocationPage;
