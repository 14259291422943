import { Form, Button } from 'react-bootstrap';
import MultiSelectView from '../../view/multi-select.view';
import { OfficeLocationRes } from '../../response/office-location.response';
import { useEffect, useState } from 'react';
import { OfficeLocationService } from '../../service/office-location.service';
import { OfficeLocationSearchReq } from '../../request/office-location.request';
import { EmployeeRoleService } from '../../service/employee-role.service';
import { EmployeeRoleRes } from '../../response/employee-role.response';
import { ItemProp } from '../../model/item-prop.model';
import { AttendanceSearchReq } from '../../request/attendance.request';
import { AttendanceService } from '../../service/attendance.service';

export interface EmployeeFilterComponentProp {
  onSubmit: any;
}
export const EmployeeFilterComponent: React.FC<EmployeeFilterComponentProp> = ({
  onSubmit,
}) => {
  const [officeLocationList, setOfficeLocationList] = useState<
    OfficeLocationRes[]
  >([]);

  const [employeeRoleList, setEmployeeRoleList] = useState<EmployeeRoleRes[]>(
    [],
  );

  const [searchText, setSearchText] = useState('');
  const [officeIdList, setOfficeIdList] = useState<any[]>([]);
  const [roleIdList, setRoleIdList] = useState<any[]>([]);

  const fetchData = async () => {
    const [officeLocationList, err] =
      await OfficeLocationService.getOfficeLocationList({
        pageNumber: 1,
        itemsPerPage: 600,
      } as OfficeLocationSearchReq);

    const [employeeRoleList, err2] =
      await EmployeeRoleService.getEmployeeRoleList({
        pageNumber: 1,
        itemsPerPage: 200,
      } as OfficeLocationSearchReq);

    setOfficeLocationList(officeLocationList);
    setEmployeeRoleList(employeeRoleList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const downloadReport = async () => {
    await AttendanceService.downLoadReportList({
      employeeIdList: [],
      startDate: (() => {
        const date = new Date();
        date.setDate(2);
        date.setHours(0, 0, 0);
        return date;
      })(),
      endDate: (() => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        date.setHours(23, 59, 59);
        return date;
      })(),
      pageNumber: 1,
      itemsPerPage: 40000,
    } as AttendanceSearchReq);
  };

  return (
    <Form
      style={{
        display: 'flex',
        height: '42px',
        gap: '24px',
        marginTop: 2,
      }}
    >
      <Form.Group controlId="officeLocationIdList">
        <MultiSelectView
          id="officeLocationIdList"
          dataList={
            officeLocationList?.map((officeLocationRes: OfficeLocationRes) => {
              return {
                label: officeLocationRes.name.en,
                value: officeLocationRes.id,
              } as ItemProp;
            }) ?? []
          }
          onChange={(data: any[]) => setOfficeIdList([...data])}
          placeholder={'Select Office Location'}
          title={'Office Location'}
          isInvalid={false}
          validationMessage={''}
        />
      </Form.Group>

      <Form.Group
        controlId="roleIdList"
        style={{
          marginLeft: 16,
        }}
      >
        <MultiSelectView
          id="roleIdList"
          dataList={
            employeeRoleList?.map((employeeRoleRes: EmployeeRoleRes) => {
              return {
                label: employeeRoleRes.name.en,
                value: employeeRoleRes.id,
              } as ItemProp;
            }) ?? []
          }
          onChange={(data: any[]) => setRoleIdList([...data])}
          placeholder={'Select Role'}
          title={'Role'}
          isInvalid={false}
          validationMessage={''}
        />
      </Form.Group>

      <Form.Group controlId="formHorizontalText">
        <Form.Control
          type="text"
          placeholder="Enter text"
          onChange={(e: any) => setSearchText(e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Button
          onClick={() => {
            onSubmit(officeIdList, roleIdList, searchText);
          }}
        >
          Submit
        </Button>
      </Form.Group>

      <Form.Group>
        <Button
          onClick={() => {
            downloadReport();
          }}
        >
          Download Report
        </Button>
      </Form.Group>
    </Form>
  );
};
