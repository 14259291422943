import {
  EmployeeRequest,
  EmployeeSearchReq,
} from '../request/employee.request';
import { EmployeeRes } from '../response/employee.response';
import httpClient from '../util/http-client';

export const EmployeeService = {
  getEmployeeById: async (id: string): Promise<(EmployeeRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/employee/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getEmployeeList: async (
    EmployeeSearchReq: EmployeeSearchReq,
  ): Promise<(EmployeeRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/employee-search', EmployeeSearchReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  saveEmployee: async (
    employeeRequest: EmployeeRequest,
  ): Promise<(EmployeeRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/employee`, employeeRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  updateEmployee: async (
    id: string,
    employeeRequest: EmployeeRequest,
  ): Promise<(EmployeeRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.put(`/employee/${id}`, employeeRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
