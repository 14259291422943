import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { EmployeeRoleRes } from '../../response/employee-role.response';
import { EmployeeRoleService } from '../../service/employee-role.service';
import { EmployeeRoleSearchReq } from '../../request/employee-role.request';
import { FloatingButtonView } from '../../view/floating-button.view';
import { I18nKey } from '../../i18n/i18n.key';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useTheme } from '../../themes/theme.provider';

const EmployeeRolePage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [employeeRoleList, setEmployeeRoleList] = useState<EmployeeRoleRes[]>(
    [],
  );
  const { openAddEmployeeRole, openUpdateEmployeeRole } = useSSNavigate();

  const fetchEmployeeRole = async () => {
    const [employeeRoleList, err] =
      await EmployeeRoleService.getEmployeeRoleList({
        pageNumber: 1,
        itemsPerPage: 200,
      } as EmployeeRoleSearchReq);

    setEmployeeRoleList(employeeRoleList);
  };

  useEffect(() => {
    fetchEmployeeRole();
  }, []);

  return (
    <section
      style={{
        paddingTop: 8,
        paddingLeft: 16,
        paddingRight: 16,
      }}
    >
      <Container>
        <Row
          className="rounded p-2"
          style={{
            backgroundColor: theme.greyColor,
          }}
        >
          <Col xs={6}>Name</Col>
          <Col xs={6}>Created Date</Col>
        </Row>
        {employeeRoleList?.map((employeeRole: EmployeeRoleRes) => {
          return (
            <Row className="rounded border p-2">
              <Col xs={6}>{employeeRole.name.en}</Col>
              <Col xs={6}>
                {dayjs(employeeRole.createdOn).format('DD MMM YYYY')}
              </Col>
            </Row>
          );
        })}
      </Container>
      <FloatingButtonView
        innerElement={
          <>
            <img src="/images/add.svg" alt="" />
            <span className="ms-2">Add Role</span>
          </>
        }
        onClick={() => {
          openAddEmployeeRole();
        }}
      />
    </section>
  );
};

export default EmployeeRolePage;
