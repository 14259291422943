import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../themes/theme.provider';
import { useSnackbar } from 'notistack';
import { EmployeeSearchReq } from '../../request/employee.request';
import { EmployeeRes } from '../../response/employee.response';
import { EmployeeService } from '../../service/employee.service';
import { OfficeLocationRes } from '../../response/office-location.response';
import { EmployeeRoleRes } from '../../response/employee-role.response';
import { FloatingButtonView } from '../../view/floating-button.view';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HeaderComponent } from '../shared/header/header.component';
import { EmployeeFilterComponent } from './employee-filter.component';
import { Image } from 'react-bootstrap';

const EmployeePage = () => {
  const itemsPerPage = 30;
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const pageNumber = useRef(1);
  const [hasMore, setHasMore] = useState(true);

  const officeIdList = useRef<string[]>([]);
  const roleIdList = useRef<string[]>([]);
  const searchText = useRef('');

  const [employeeList, setEmployeeList] = useState<EmployeeRes[]>([]);
  const { openUpdateEmployee, openAddEmployee, openEmployeeDetail } =
    useSSNavigate();

  const fetchEmployee = async () => {
    const [_employeeList, err] = await EmployeeService.getEmployeeList({
      searchText: searchText.current,
      officeIdList: officeIdList.current,
      roleIdList: roleIdList.current,
      pageNumber: pageNumber.current,
      itemsPerPage: itemsPerPage,
    } as EmployeeSearchReq);

    if (!err) {
      if (pageNumber.current == 1) {
        setEmployeeList(_employeeList);
      } else {
        setEmployeeList((previous) => [...previous, ..._employeeList]);
      }

      if (_employeeList && _employeeList.length > 0) {
        pageNumber.current = pageNumber.current + 1;
      }

      if (_employeeList && _employeeList.length == itemsPerPage) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  const refresh = () => {
    pageNumber.current = 1;
    fetchEmployee();
  };

  const searchEmployee = (
    _officeIdList: string[],
    _roleIdList: string[],
    _searchText: string,
  ) => {
    pageNumber.current = 1;
    searchText.current = _searchText;
    officeIdList.current = _officeIdList;
    roleIdList.current = _roleIdList;
    fetchEmployee();
  };

  return (
    <>
      <HeaderComponent
        childElement={<EmployeeFilterComponent onSubmit={searchEmployee} />}
        title={'Employee Details'}
      />
      <section
        id="scrollableEmployee"
        style={{
          height: `calc(100vh - 48px)`,
          overflow: 'auto',
          paddingTop: 8,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <InfiniteScroll
          dataLength={employeeList.length}
          next={fetchEmployee}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableEmployee"
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>
              &#8595; Pull down to refresh
            </h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          }
        >
          <Container>
            <Row
              className="rounded p-2"
              style={{
                backgroundColor: theme.greyColor,
              }}
            >
              <Col xs={3}>Name</Col>
              <Col xs={1}>Photo</Col>
              <Col xs={2}>Phone</Col>
              <Col xs={1}>Online</Col>
              <Col xs={2}>Office Location</Col>
              <Col xs={2}>Role</Col>
              <Col xs={1}>Actions</Col>
            </Row>
            {employeeList?.map((employee: EmployeeRes, index: number) => {
              return (
                <Row
                  className="rounded border p-2"
                  key={`${employee.id}_${index}`}
                >
                  <Col xs={3}>
                    <span>{employee.employeeInfo.name}</span>
                    <Button
                      variant="link"
                      className="text-primary text-decoration-underline p-0 font-weight-600"
                      onClick={() => {
                        openEmployeeDetail(employee.id);
                      }}
                      style={{ marginLeft: 2 }}
                    >
                      <img
                        src="/images/visibility_green.svg"
                        alt=""
                        width={20}
                        height={20}
                      />
                    </Button>
                  </Col>

                  <Col xs={1}>
                    {employee.verifiedPhoto?.url?.main ? (
                      <Image
                        src={employee.verifiedPhoto?.url?.main}
                        roundedCircle
                        width={36}
                        height={36}
                      />
                    ) : (
                      'NA'
                    )}
                  </Col>

                  <Col xs={2}>+91 {employee.employeeInfo.phone}</Col>
                  {employee.online ? (
                    <Col xs={1} className="text-success">
                      Online
                    </Col>
                  ) : (
                    <Col xs={1} className="text-danger">
                      Offline
                    </Col>
                  )}
                  <Col xs={2}>
                    {employee.officeLocationList
                      ?.map((officeLocation: OfficeLocationRes) => {
                        return officeLocation.name.en;
                      })
                      ?.toString()}
                  </Col>
                  <Col xs={2}>
                    {employee.roleList
                      ?.map((role: EmployeeRoleRes) => {
                        return role.name.en;
                      })
                      ?.toString()}
                  </Col>
                  <Col xs={1}>
                    <Button
                      variant="link"
                      className="text-primary text-decoration-underline p-0 font-weight-600"
                      onClick={() => {
                        openUpdateEmployee(employee.id);
                      }}
                    >
                      <img
                        src="/images/edit_button.svg"
                        alt=""
                        width={20}
                        height={20}
                      />
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </Container>
        </InfiniteScroll>
        <FloatingButtonView
          innerElement={
            <>
              <img src="/images/add.svg" alt="" />
              <span className="ms-2">Add Employee</span>
            </>
          }
          onClick={() => {
            openAddEmployee();
          }}
        />
      </section>
    </>
  );
};

export default EmployeePage;
