import { AttendanceSearchReq } from '../request/attendance.request';
import { AttendanceRes } from '../response/attendance.response';
import httpClient from '../util/http-client';
const FileDownload = require('js-file-download');

export const AttendanceService = {
  getAttendanceList: async (
    attendanceSearchReq: AttendanceSearchReq,
  ): Promise<(AttendanceRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(
        '/attendance-search',
        attendanceSearchReq,
      );
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },

  downLoadReportList: async (attendanceSearchReq: AttendanceSearchReq) => {
    httpClient
      .post('/attendance/download-report', attendanceSearchReq, {
        responseType: 'blob',
      })
      .then((response: any) => {
        FileDownload(response.data, 'attendance-report.csv');
      });
  },
};
